<template>
  <div class="liveTv-container">
    <div class="swiper-container liveTv-banner">
      <div class="swiper-wrapper">
        <div v-for="(item, index) in bannerList" :key="item.id" :data-index="index" class="swiper-slide banner-item">
          <img :src="item.url" alt="">
        </div>
      </div>
      <!-- <div class="swiper-pagination liveTv-swiper-pagination"></div> -->
    </div>
    <float-Menu />
  </div>
</template>

<script>
import Swiper from 'swiper/js/swiper.js'
import 'swiper/css/swiper.css'
import floatMenu from '@/components/floatMenu/index'
export default {
  name: 'liveTv',
  components: { floatMenu },
  data() {
    return {
      bannerList: [
        { id: 1, url: require('../../assets/images/liveTv/banner1.jpg') },
        { id: 2, url: require('../../assets/images/liveTv/banner2.png') }
      ]
    }
  },
  mounted() {
    this.initSwiper()
  },
  methods: {
    initSwiper() {
      // const vm = this
      this.swiper = new Swiper ('.liveTv-banner', {
        direction : 'vertical',
        slidesPerView: 1,
        loop: true,
        // pagination: {
        //   el: '.liveTv-swiper-pagination',
        // }
      })        
    }
  }
}
</script>

<style lang="less" scoped>
  .liveTv-container {
    width: 100%;
    height: 100vh;
    .liveTv-banner {
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
</style>